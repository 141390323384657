<template>
  <div>
    <h4>{{ this.$t("admin.excel.departments.title") }}</h4>
    <div style="margin-bottom: 10px;">
      <button
        style="margin-right: 5px;"
        class="btn ml-2 font-weight-bold btn-success"
        :disabled="validated || isSubmitting"
        @click="send"
      >
        {{ this.$t("admin.excel.button_send") }}
      </button>
      <label
        :style="{
          float: 'right',
          color: this.error.length > 0 ? 'red' : 'black'
        }"
        >{{
          this.$t("admin.excel.count_errors") + " " + this.error.length
        }}</label
      >
      <label>
        {{
          this.$t("admin.excel.request_sent") +
            " " +
            ((this.responseCount / this.totalRows) * 100).toFixed(0) +
            "%"
        }}</label
      >
    </div>
    <ag-grid-vue
      ref="agGrid"
      style="width: 100%;"
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      domLayout="autoHeight"
      rowSelection="multiple"
      @grid-ready="onGridReady"
      @selectionChanged="onSelectionChanged"
    >
    </ag-grid-vue>
    <div class="col-4 d-flex align-items-start mt-5">
      <button
        class="btn mr-2 font-weight-bold btn-sm btn-primary"
        @click="addRow"
      >
        {{ $t("admin.excel.button_add_row") }}
      </button>
      <button
        class="btn font-weight-bold btn-sm btn-danger"
        @click="deleteSelectedRows"
      >
        {{
          $t("assets.buttons.delete_departments", {
            departments: selectedRowCount == 0 ? "" : selectedRowCount
          })
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { validText } from "@/utils/validators.js";
import Autocomplete from "@/components/form/Autocomplete.component";
import UpperCaseInput from "@/components/form/UpperCaseInput.component";
import axios from "axios";
import _ from "lodash";
import {
  getDepartments,
  sendExcelDepartmentsData
} from "@/api/admin/stakeholders.api";
import { mapActions } from "vuex";

export default {
  name: "DepartmentsExcel",
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    Autocomplete,
    // eslint-disable-next-line vue/no-unused-components
    UpperCaseInput
  },
  data() {
    return {
      columnDefs: [],
      rowData: null,
      error: [],
      departments: [],
      departmentExists: false,
      responseCount: 0,
      totalRows: null,
      departmentsName: [],
      allDepartments: [],
      isSubmitting: false,
      selectedRowCount: 0
    };
  },
  methods: {
    ...mapActions("Admin", ["loadDepartments"]),
    setupColumnDefs() {
      this.columnDefs = [
        {
          headerName: this.$t("admin.excel.department.header_department"),
          field: this.$t("excel_headers.department"),
          width: 400,
          cellDataType: "text",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          filter: "agTextColumnFilter",
          cellEditor: "UpperCaseInput",
          cellStyle: params => {
            if (params.data["status"] === "success") {
              return { backgroundColor: "#bdecb6" };
            }
            if (!this.departmentsName.includes(params.value)) {
              this.updateDepartments();
            }
            this.departmentExists = false;
            _.each(this.allDepartments, department => {
              if (
                department.department === params.value &&
                department.order !== params.data["rowId"]
              ) {
                this.departmentExists = true;
              }
            });
            if (
              !params.value ||
              !validText(params.value) ||
              this.departmentExists ||
              (params.data["status"] === "error" &&
                params.data["errorValue"] === params.value)
            ) {
              if (!this.error.includes(params.data["rowId"] + "Department")) {
                this.error.push(params.data["rowId"] + "Department");
              }
              return { backgroundColor: "#FF6961" };
            }
            let array = this.error.filter(
              item => item !== params.data["rowId"] + "Department"
            );
            this.error = array;
            return { backgroundColor: "white" };
          },
          tooltipValueGetter: params => {
            if (!params.value || !validText(params.value)) {
              return this.$t("errors.format");
            }
            if (this.departmentExists) {
              return this.$t("errors.ND1024");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value
            ) {
              return this.$t("errors." + params.data["errorCode"]);
            }
            return null;
          },
          onCellValueChanged: params => {
            if (params.data["status"] === "error") {
              const row = _.find(this.rowData, {
                rowId: params.data["rowId"]
              });

              if (row) {
                row.status = "modified";
              }
            }
            this.updateNewDepartments();
            this.updateDepartments();
          },
          editable: true
        },
        {
          headerName: this.$t(
            "admin.excel.department.header_parent_department"
          ),
          field: this.$t("excel_headers.parentDepartment"),
          width: 150,
          cellDataType: "text",
          filter: "agTextColumnFilter",
          cellEditor: "Autocomplete",
          cellEditorParams: {
            values: this.sortParentDepartmentArray(this.departmentsName)
          },
          cellStyle: params => {
            if (params.data["status"] === "success") {
              return { backgroundColor: "#bdecb6" };
            }
            if (
              !this.departmentsName.includes(params.value) ||
              !params.value ||
              params.data["Department"] === params.data["Parent Department"]
            ) {
              if (
                !this.error.includes(params.data["rowId"] + "Parent Department")
              ) {
                this.error.push(params.data["rowId"] + "Parent Department");
              }
              return { backgroundColor: "#FF6961" };
            }
            let array = this.error.filter(
              item => item !== params.data["rowId"] + "Parent Department"
            );
            this.error = array;
            return { backgroundColor: "white" };
          },
          tooltipValueGetter: params => {
            if (!this.departmentsName.includes(params.value) || !params.value) {
              return this.$t("errors.ND1030");
            }
            if (
              params.data["Department"] === params.data["Parent Department"]
            ) {
              return this.$t("errors.ND1030");
            }
            return null;
          },
          editable: true
        },
        {
          headerName: this.$t("admin.excel.department.header_position"),
          field: this.$t("excel_headers.position"),
          width: 130,
          filter: "agTextColumnFilter",
          cellStyle: params => {
            if (params.data["status"] === "success") {
              return { backgroundColor: "#bdecb6" };
            }
            if (
              !validText(params.value) ||
              (params.data["status"] === "error" &&
                params.data["errorValue"] === params.value)
            ) {
              if (!this.error.includes(params.data["rowId"] + "Position")) {
                this.error.push(params.data["rowId"] + "Position");
              }
              return { backgroundColor: "#FF6961" };
            }
            let array = this.error.filter(
              item => item !== params.data["rowId"] + "Position"
            );
            this.error = array;
            return { backgroundColor: "white" };
          },
          tooltipValueGetter: params => {
            if (!validText(params.value)) {
              return this.$t("errors.format");
            }
            if (
              params.data["status"] === "error" &&
              params.data["errorValue"] === params.value
            ) {
              return this.$t("errors." + params.data["errorCode"]);
            }
            return null;
          },
          editable: true
        }
      ];
    },
    async send() {
      this.isSubmitting = true;
      this.responseCount = 0;
      const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
      let departments = this.setDepartmentsLevel();

      const mapData = rowData => {
        const parentDepartmentName = _.toString(rowData["Parent Department"]);
        const foundDepartment = _.find(this.departments, {
          name: parentDepartmentName
        });
        return {
          name: rowData["Department"],
          stakeholderDepartmentParentId: foundDepartment
            ? foundDepartment.stakeholderId
            : -1,
          tags: rowData["Position"],
          level: rowData["level"],
          rowId: rowData["rowId"]
        };
      };

      const sendData = async mappedData => {
        try {
          const response = await sendExcelDepartmentsData(
            stakeholderCompanyId,
            mappedData
          );
          this.responseCount++;
          return response;
        } catch (error) {
          this.responseCount++;
          return error.response;
        }
      };

      const processResponses = responses => {
        _.each(responses, response => {
          if (response.data._meta.status === "ERROR") {
            const item = _.find(this.rowData, {
              rowId: response.data.records.more.rowId
            });
            if (item) {
              item.status = "error";
              item.errorValue = response.data.records.more.value;
              item.errorMessage = response.data.records.devMessage;
              item.errorCode = response.data.records.applicationCode;
            }
          } else {
            const item = _.find(this.rowData, {
              rowId: response.data.records.rowId
            });
            if (item) {
              item.status = "success";
            }
            this.departments.push({
              name: response.data.records.name,
              stakeholderId: response.data.records.stakeholderId
            });
          }
        });
        this.setupColumnDefs();
      };

      const processDepartmentChunks = async (chunks, level) => {
        if (chunks.length === 0) {
          return;
        }

        const chunk = chunks.shift();
        const promises = chunk.map(department => sendData(department));

        try {
          const responses = await axios.all(promises);
          processResponses(responses);
        } catch (error) {
          this.setupColumnDefs();
          return;
        }

        await processDepartmentChunks(chunks, level);
      };

      (async () => {
        let level = 0;
        let chunkSize = 50;
        // eslint-disable-next-line
        while (true) {
          const requests = departments
            .filter(row => row.status !== "success")
            .map(row => mapData(row));
          const departmentsByLevel = requests.filter(
            dept => dept.level === level
          );

          if (departmentsByLevel.length === 0) {
            break;
          }

          const chunks = _.chunk(departmentsByLevel, chunkSize);

          await processDepartmentChunks(chunks, level);

          level++;
        }

        if (this.errorCount > 0) {
          this.$swal({
            title: this.$t("admin.excel.warning.title"),
            text:
              this.$t("admin.excel.warning.success_request") +
              " " +
              this.successCount +
              " " +
              this.$t("admin.excel.warning.failed_requests") +
              " " +
              this.errorCount,
            icon: "warning"
          });
        } else {
          this.$swal
            .fire({
              title: this.$t("admin.excel.success.title"),
              text: this.$t("admin.excel.success.subtitle"),
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "OK",
              cancelButtonText: this.$t("admin.excel.button_redirect")
            })
            .then(result => {
              if (!result.isConfirmed) {
                this.loadDepartments();
                this.$router.push({
                  name: "DepartmentsList"
                });
              }
            });
        }
        this.isSubmitting = false;
      })();
    },
    updateNewDepartments() {
      this.allDepartments = _.map(this.rowData, department => ({
        order: department["rowId"],
        department: department["Department"]
      }));
    },
    updateDepartments() {
      this.departmentsName = [];
      this.departmentsName.push("---");
      _.each(this.departments, department => {
        this.departmentsName.push(department.name);
      });
      _.each(this.rowData, data => {
        this.departmentsName.push(data["Department"]);
      });
      this.setupColumnDefs();
    },
    setDepartmentsLevel() {
      const departments = this.rowData;

      _.each(departments, department => {
        if (department["Parent Department"] === "---") {
          department["Parent Department"] = null;
        }
      });

      function getDepartmentByName(name) {
        return departments.find(dept => dept["Department"] === name);
      }

      function assignLevels(deptName, level) {
        const dept = getDepartmentByName(deptName);
        if (!dept) return;

        dept["level"] = level;
        const children = departments.filter(
          d => d["Parent Department"] === deptName
        );
        children.forEach(child => {
          assignLevels(child["Department"], level + 1);
        });
      }

      let allAssigned = false;
      while (!allAssigned) {
        allAssigned = true;
        departments.forEach(dept => {
          if (dept["level"] === undefined) {
            assignLevels(dept["Department"], 0);
            allAssigned = false;
          }
        });
      }

      return departments;
    },
    sortParentDepartmentArray(array) {
      const filteredArray = array.filter(value => !_.isEmpty(value));
      const sortedArray = _.sortBy(filteredArray, value =>
        value === "---" ? "" : value
      );
      return sortedArray;
    },
    onGridReady(params) {
      this.gridApi = params.api;
    },
    addRow() {
      const newItem = {
        Department: "",
        "Parent Department": "---",
        Position: null,
        rowId: this.rowData.slice(-1)[0].rowId + 1
      };
      this.rowData.push(newItem);
      this.setupColumnDefs();
      this.gridApi.setRowData(this.rowData);
    },
    deleteSelectedRows() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.$swal({
        html: this.$t("admin.popup.delete.multi_department", {
          departments: selectedRows.length,
          confirmation: this.$t("admin.popup.delete.delete_confirmation")
        }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0BB783",
        confirmButtonText: this.$t("admin.popup.confirmButton"),
        cancelButtonText: this.$t("admin.popup.cancelButton"),
        input: "text",
        inputValidator: value => {
          if (value === this.$t("admin.popup.delete.delete_confirmation")) {
            selectedRows.forEach(row => {
              const index = this.rowData.findIndex(r => r.rowId === row.rowId);
              if (index !== -1) {
                const filteredArray = this.error.filter(
                  str => !str.startsWith(row.rowId)
                );
                this.error = filteredArray;
                this.rowData.splice(index, 1);
                this.updateDepartments();
              }
            });
            this.setupColumnDefs();
            this.gridApi.setRowData(this.rowData);
          } else {
            return this.$t("admin.popup.incorrectWord");
          }
        }
      });
    },
    onSelectionChanged() {
      this.selectedRowCount = this.gridApi.getSelectedNodes().length;
    }
  },
  mounted() {
    const stakeholderCompanyId = localStorage.getItem("managedCompanyId");
    axios.all([getDepartments(stakeholderCompanyId)]).then(
      axios.spread(responseDepartments => {
        this.departments = responseDepartments.data.records;
        _.each(this.departments, department => {
          this.departmentsName.push(department.name);
        });
        const excelData = this.$route.query.excelData;
        if (excelData) {
          _.each(excelData, department => {
            if (!department["Parent Department"]) {
              department["Parent Department"] = "---";
            } else {
              department["Parent Department"] = _.toString(
                department["Parent Department"]
              ).toUpperCase();
            }
            department["Department"] = _.toString(
              department["Department"]
            ).toUpperCase();
          });
          this.allDepartments = _.map(excelData, department => ({
            order: department["rowId"],
            department: _.toString(department["Department"])
          }));
          this.departmentsName.push("---");
          _.each(excelData, data => {
            if (!this.departmentsName.includes(data["Department"])) {
              this.departmentsName.push(_.toString(data["Department"]));
            }
          });
          this.totalRows = excelData.length;
          this.rowData = excelData;
        }
        this.setupColumnDefs();
      })
    );
  },
  computed: {
    validated() {
      if (this.error.length > 0) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>
